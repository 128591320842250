import React, { useEffect, useRef, useState } from "react";
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import {
  countryListStart,
  createQuoteStart,
  createQuoteSuccess,
  setBusinessCurrencyList,
  setCurrentBusinessQuoteData,
  setCurrentIndividualQuoteData,
  setCurrentQuoteData,
  setIndividualCurrencyList,
} from "../../store/slices/BeneficiariesSlice";
import { ButtonLoader } from "../Helper/Loader";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { set } from "lodash";

const SingleTransactionFields = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("currency_conversion");
  const profile = useSelector((state) => state.admin.profile);
  const createQuote = useSelector((state) => state.beneficiaries.createQuote);
  const [recipientType, setRecipientType] = useState(1);
  const [countryCodeList, setCountryCodeList] = useState({
    currencies: [],
    default_amount: 0, 
  });
  const [selectedRecivingCurrency, setSelectedRecivingCurrency] = useState(null);
  const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
  const [exchangeData, setExchangeData] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [quoteType, setQuoteType] = useState("FORWARD");
  const [exchangeAmount, setExchangeAmount] = useState(0);
  const countriesLists = useSelector((state) => state.beneficiaries.countryList);
  const individualCurrencyList = useSelector((state) => state.beneficiaries.individualCurrencyList);
  const businessCurrencyList = useSelector((state) => state.beneficiaries.businessCurrencyList);

  const currentIndividualQuoteData = useSelector((state) => state.beneficiaries.currentIndividualQuoteData);
  const currentBusinessQuoteData = useSelector((state) => state.beneficiaries.currentBusinessQuoteData);

  const [currentFormData, setCurrentFormData] = useState({
    recipient_type: recipientType,
    receiving_currency: "",
    recipient_country: "",
    amount: "",
    recipient_amount: "",
    quote_type: quoteType,
  })
  
  const formRef = useRef();
  const [selectedFromCountry, setSelectedFromCountry] = useState({
    label: `${"USA"} (${"USD"})`,
    value: "USD",
    flag: "https://cms-efibank-staging.rare-able.com/images/countries/us.png",
  });

  const validationSchema = Yup.object().shape({
    receiving_currency: Yup.string().required(t("required")),
    recipient_country: Yup.string().required(t("required")),
    recipient_type: Yup.string().required(t("required")),
    amount: Yup.number()
      .required(t("required"))
      .min(1, t("amount.at_least"))
      .max(100000, t("amount.max")),
  });

  // useEffect(()=> {
  //   if(props.transferData && Object.keys(props.transferData).length > 0){
  //     let data = countryCodeList.find(item => item.value.slice(3) == props.transferData.recipient_country) || countryCodeList[0]
  //     setSelectedRecivingCountry(data);
  //     setSelectedRecivingCurrency(data);
  //   }
  // }, [])

  useEffect(() => {
    // if(!props.transferData){
      if(recipientType == 1){
        if(Object.keys(individualCurrencyList).length > 0){
          setCountryCodeList(individualCurrencyList);
          if(quoteType == "FORWARD"){
            formRef.current?.setFieldValue("amount", individualCurrencyList.default_amount);
          } else {
            formRef.current?.setFieldValue("recipient_amount", individualCurrencyList.default_amount);
          }
        } else {
          dispatch(countryListStart({ recipient_type: recipientType }));
        }
      } else {
        if(Object.keys(businessCurrencyList).length > 0){
          setCountryCodeList(businessCurrencyList);
          if(quoteType == "FORWARD"){
            formRef.current?.setFieldValue("amount", businessCurrencyList.default_amount);
          } else {
            formRef.current?.setFieldValue("recipient_amount", businessCurrencyList.default_amount);
          }
        } else {
          dispatch(countryListStart({ recipient_type: recipientType }));
        }
      }
    // } 
  }, [recipientType]);

  useEffect(() => {
    if (
      // !skipRender &&
      !countriesLists.loading &&
      Object.keys(countriesLists.data).length > 0
    ) {

      let values = countriesLists.data.receipient_type_countries.flatMap((country) =>
        country.destination_currencies.map((currency) => ({
          label: `${currency} - ${country.destination_country}`,
          value: `${currency}${country.destination_country_code}`,
          flag: country.flag,
        }))
      )
      let currencyListData = {
        currencies: values,
        default_amount: countriesLists.data.quote_default_from_amount,
      };
      setCountryCodeList(currencyListData);
      dispatch(countriesLists.data.recipient_type == 1 ? setIndividualCurrencyList(currencyListData) : setBusinessCurrencyList(currencyListData));
      if(quoteType == "FORWARD"){
        formRef.current?.setFieldValue("amount", countriesLists.data.quote_default_from_amount);
      } else {
        formRef.current?.setFieldValue("recipient_amount", countriesLists.data.quote_default_from_amount);
      }
    }
    // setSkipRender(false);
  }, [countriesLists]);

  useEffect(() => {
    if (Object.keys(countryCodeList).length > 0 && countryCodeList.currencies?.length > 0 && !props.transferData) {
      let defaultData = countryCodeList.currencies?.find((item) => item.value.slice(3) == countriesLists.data.quote_default_to_country) || countryCodeList.currencies[0];
      if(recipientType == 1){
        if(currentFormData && currentFormData.receiving_currency && currentFormData.recipient_country){
          let data = countryCodeList.currencies.find(item => item.value == currentFormData.receiving_currency + currentFormData.recipient_country);
          if(data) {
            setSelectedRecivingCountry(data);
            setSelectedRecivingCurrency(data);
            formRef.current?.setFieldValue("receiving_currency", currentFormData.receiving_currency);
            formRef.current?.setFieldValue("recipient_country", currentFormData.recipient_country);
            if(currentFormData.quote_type == "FORWARD"){
              formRef.current?.setFieldValue("amount", currentFormData.amount);
              formRef.current?.setFieldValue("recipient_amount", 0);
            } else {
              formRef.current?.setFieldValue("amount", 0);
              formRef.current?.setFieldValue("recipient_amount", currentFormData.recipient_amount);
            }
            handleDataReset(data, quoteType == "FORWARD" ? currentFormData.amount : currentFormData.recipient_amount);
          } else {
            handleSetEmptyData();
          }
        } else {
          formRef.current?.setFieldValue("receiving_currency", defaultData.value.slice(0, 3));
          formRef.current?.setFieldValue("recipient_country", defaultData.value.slice(3));
          setSelectedRecivingCountry(defaultData);
          setSelectedRecivingCurrency(defaultData);
          handleDataReset(defaultData, countryCodeList.default_amount) ;
        }
      } else {
        if(currentFormData && currentFormData.receiving_currency && currentFormData.recipient_country){
          let data = countryCodeList.currencies.find(item => item.value == currentFormData.receiving_currency + currentFormData.recipient_country);
          if(data) {
            setSelectedRecivingCountry(data);
            setSelectedRecivingCurrency(data);
            formRef.current?.setFieldValue("receiving_currency", currentFormData.receiving_currency);
            formRef.current?.setFieldValue("recipient_country", currentFormData.recipient_country);
            if(currentFormData.quote_type == "FORWARD"){
              formRef.current?.setFieldValue("amount", currentFormData.amount);
              formRef.current?.setFieldValue("recipient_amount", 0);
            } else {
              formRef.current?.setFieldValue("amount", 0);
              formRef.current?.setFieldValue("recipient_amount", currentFormData.recipient_amount);
            }
            // handleDataReset(data, currentFormData.amount);
          } else {
            handleSetEmptyData();
            // handleDataReset(defaultData, countryCodeList.default_amount);
          }
        } else {
          formRef.current?.setFieldValue("receiving_currency",defaultData.value.slice(0, 3));
          formRef.current?.setFieldValue("recipient_country", defaultData.value.slice(3));
          setSelectedRecivingCountry(defaultData);
          setSelectedRecivingCurrency(defaultData);
          // handleDataReset(defaultData, countryCodeList.default_amount);
        }
      } 
    } 
  }, [countryCodeList]);

  const handleDataReset = (data, amount) => {
    if(data && amount){
      handleSubmit({
        ...formRef.current?.values,
        amount : amount,
        recipient_amount: amount,
        receiving_currency: data.value.slice(0, 3),
        recipient_country: data.value.slice(3),
      });
    }
  }

  const handleSetEmptyData = () => {
    if(formRef.current){
      setSelectedRecivingCountry(null);
      setSelectedRecivingCurrency(null);
      formRef.current.setFieldValue("receiving_currency", "");
      formRef.current.setFieldValue("recipient_country", "");
      formRef.current.setFieldValue("amount", 0);
      formRef.current.setFieldValue("recipient_amount", 0);
      getErrorNotificationMessage("The selected currency is not available for the selected recipient type. Please select values again!");
    }
  }

  const handleSubmit = (values) => {
    setExchangeData(false);
    const { recipient_amount, ...rest } = values;
    let fromAmount = quoteType == "FORWARD" ? values.amount : values.recipient_amount
    setCurrentFormData({
      ...currentFormData,
      amount: quoteType == "FORWARD" ? values.amount : values.recipient_amount,
      receiving_currency: values.receiving_currency,
      recipient_country: values.recipient_country,
      recipient_amount: values.recipient_amount,
      quote_type: quoteType,
    })
    if(fromAmount >= 1 && fromAmount <= 100000){
      dispatch(createQuoteStart({
        ...rest,
        quote_type: quoteType,
        amount: fromAmount,
      }));
    }
    props.setTransferData(values);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !createQuote.loading &&
      Object.keys(createQuote.data).length > 0
    ) {
      setExchangeData(createQuote.data.quote_exchange_rate);
      props.setTransferData({
        ...props.transferData,
        exchange_data: createQuote.data.quote_exchange_rate,
        quote: createQuote.data.quote,
      });
      setExchangeAmount(quoteType == "FORWARD" ?  createQuote.data.quote_exchange_rate.recipient_amount.amount : createQuote.data.quote_exchange_rate.converted_amount.amount);
      if(quoteType == "FORWARD"){
        formRef.current?.setFieldValue("recipient_amount", createQuote.data.quote_exchange_rate.recipient_amount.amount);
      } else {
        formRef.current?.setFieldValue("amount", createQuote.data.quote_exchange_rate.converted_amount.amount);
      }
      if(createQuote.data.quote.quote.recipient_type == "INDIVIDUAL"){
        dispatch(setCurrentIndividualQuoteData(createQuote.data.quote));
      } else {
        dispatch(setCurrentBusinessQuoteData(createQuote.data.quote));
      }
    }
    setSkipRender(false);
  }, [createQuote]);

  useEffect(()=> {
    if(quoteType == "REVERSE"){
      formRef.current?.setFieldValue("amount", exchangeAmount);
    } else {
      formRef.current?.setFieldValue("recipient_amount", exchangeAmount);
    }
  }, [exchangeAmount])

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "transparent!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "0.95em",
      fontWeight: "500",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "500",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#1b3d86!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#dde1e6",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#1b3d86' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#1b3d86",
              color: "#fff"
            },
    }),
  };

  const SingleValue = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <img
        src={data.flag}
        alt=""
        style={{
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      />
      {data.label}
    </components.SingleValue>
  );

  const Option = ({ data, ...props }) => (
    <components.Option {...props}>
      <img
        src={data.flag}
        alt=""
        style={{
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      />
      {data.label}
    </components.Option>
  );

  // useEffect(()=> {
  //   if((props.transferData && countryCodeList.length > 0) && Object.keys(props.transferData).length > 0){
  //     let data = countryCodeList.find(item => item.value.slice(3) == props.transferData.recipient_country);
  //     setSelectedRecivingCountry(data);
  //     setSelectedRecivingCurrency(data);
  //   }
  // }, [props, countryCodeList])

  return (
    <>
      <div className="single-transaction-frame">
        <div className="single-transaction-titles">
          <h4>Send Money</h4>
          <p>
            Effortlessly send funds with our streamlined process. Designed for
            efficiency and security, our platform ensures a fast, reliable, and
            secure transfer experience every time.
          </p>
        </div>
        <div className="single-transaction-content">
          {countriesLists.loading ? (
            <Skeleton count={3} height={100} />
          ) : Object.keys(countriesLists.data).length > 0 ? (
            <Formik
              initialValues={{
                // user_type: profile.data.user_type,
                // recipient_type: props.transferData?.recipient_type || recipientType,
                // receiving_currency: props.transferData?.receiving_currency || "",
                // recipient_country: props.transferData?.recipient_country || "",
                // amount: props.transferData?.amount || countriesLists.data.quote_default_from_amount,
                // recipient_amount: "",
                user_type: profile.data.user_type,
                recipient_type: recipientType,
                receiving_currency: "",
                recipient_country: "",
                amount: "",
                recipient_amount: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              innerRef={formRef}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                setValues,
                isValid,
              }) => (
                <FORM>
                  {values.user_type == 2 ? (
                    <div className="single-transaction-box-field mb-3">
                      <div className="beneficiary-from-heading">
                        <h3>{t("beneficiary_type")}</h3>
                      </div>
                      <Col md={12} className="mt-3">
                        <Form.Group className="mb-3">
                          <Radio
                            name="radio"
                            onClick={(e) => {
                              setFieldValue("recipient_type", e.target.value);
                              props.setTransferData(null);
                              setRecipientType(1);
                              // handleSetEmptyData();
                            }}
                            value="1"
                            checked={values.recipient_type == 1}
                            disabled={createQuote.buttonDisable}
                          >
                            {t("individual")}
                          </Radio>
                          <Radio
                            name="radio"
                            onClick={(e) => {
                              setFieldValue("recipient_type", e.target.value);
                              props.setTransferData(null);
                              setRecipientType(2);
                              // handleSetEmptyData();
                            }}
                            value="2"
                            checked={values.recipient_type == 2}
                            disabled={createQuote.buttonDisable}
                          >
                            {t("business")}
                          </Radio>
                          <ErrorMessage
                            component={"div"}
                            name="recipient_type"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    </div>
                  ) : null}
                  <div className="single-transaction-box-field mb-3">
                    <Form.Label className="default-form-label">From</Form.Label>
                    <div className="single-transaction-box">
                      <div className="single-transaction-fields">
                        <Form.Group>
                          <Field
                            type="number"
                            className="form-control"
                            name="amount"
                            placeholder={t("amount.placeholder")}
                            disabled={createQuote.buttonDisable}
                            min={1}
                            value={values.amount}
                            onChange={(e) => {
                              setQuoteType("FORWARD");
                              setFieldValue("amount", e.target.value);
                            }}
                            onBlur={(e) => {
                              if(values.amount < 1 || values.amount > 100000){
                                getErrorNotificationMessage("Amount must be between 1 to 100000")
                              } else if(!values.receiving_currency){
                                // getErrorNotificationMessage("Select Currency");
                              } else {
                                // isValid ? handleSubmit(values) : getErrorNotificationMessage("Invalid!");
                                isValid && handleSubmit(values);
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="single-transaction-country">
                        <Select
                          options={[selectedFromCountry]}
                          styles={customStyles}
                          value={selectedFromCountry}
                          classNamePrefix="react-select"
                          placeholder="Currency"
                          components={{ SingleValue, Option }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="amount"
                      className="text-danger"
                    />
                  </div>
                  <div className="single-transaction-box-field">
                    <Form.Label className="default-form-label">To</Form.Label>
                    <div className="single-transaction-box">
                      <div className="single-transaction-fields">
                        <Form.Group>
                          <Field
                            type="number"
                            className="form-control"
                            name="recipient_amount"
                            placeholder={t("amount.placeholder")}
                            disabled={createQuote.buttonDisable}
                            min={1}
                            value={values.recipient_amount}
                            onChange={(e) => {
                              setQuoteType("REVERSE");
                              setFieldValue("recipient_amount", e.target.value);
                            }}
                            onBlur={(e) => {
                              if(values.recipient_amount < 1 || values.recipient_amount > 100000){
                                getErrorNotificationMessage("Amount must be between 1 to 100000")
                              } else if(!values.receiving_currency){
                                // getErrorNotificationMessage("Select Currency");
                              } else {
                                // isValid ? handleSubmit(values) : getErrorNotificationMessage("Invalid!");
                                isValid && handleSubmit(values);
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="single-transaction-country">
                        <Select
                          options={countryCodeList.currencies}
                          styles={customStyles}
                          name="receiving_currency"
                          classNamePrefix="react-select"
                          placeholder="Select Currency"
                          value={selectedRecivingCurrency}
                          onChange={(data) => {
                            props.setTransferData(null);
                            setFieldValue("receiving_currency", data.value.slice(0, 3));
                            setFieldValue("recipient_country",data.value.slice(3));
                            setSelectedRecivingCountry(data);
                            setSelectedRecivingCurrency(data);
                            // isValid ?  : getErrorNotificationMessage("Invalid");
                            isValid && handleSubmit({
                              ...values,
                              receiving_currency: data.value.slice(0, 3),
                              recipient_country: data.value.slice(3),
                            });
                          }}
                          isDisabled={createQuote.buttonDisable}
                          components={{ SingleValue, Option }}
                        />
                      </div>
                    </div>
                    {!values.receiving_currency ? (
                      <div className="currency-end">
                        <ErrorMessage
                          component={"div"}
                          name="receiving_currency"
                          className="text-danger"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="single-space"></div>
                  <div className="single-transaction-info-box">
                    {createQuote.buttonDisable ? (
                      <div className="transaction-btn">
                        <ButtonLoader />
                      </div>
                    ) : (Object.keys(createQuote.data).length > 0 && props.transferData?.exchange_data) ? (
                      <React.Fragment>
                        <div className="single-transaction-info-card">
                          <h4>Exchange rate:</h4>
                          <h5>
                            {props.transferData.exchange_data.converting_currency}
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 64 64"
                              >
                                <g fill="#1b3d86">
                                  <path
                                    d="M53.121 13.594L36.08 4.25a2.027 2.027 0 00-2.977 1.773v2.9c-13.996.018-22.815 9.592-23.516 17.746l.088 2.693c5.963-6.725 15.16-9.554 22.605-9.216v3.8a2.027 2.027 0 002.81 1.851l17.796-7.639a2.559 2.559 0 00.235-4.564z"
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M12.104 29.877l-1.841 1.944a17.582 17.582 0 004.143 6.581.988.988 0 001.665-.725c-.226-4.1.79-10.006 1.86-12.35q-.363.195-.737.416a23.582 23.582 0 00-5.09 4.134zm-1.225 20.529l17.041 9.343a2.027 2.027 0 002.977-1.772v-2.9c13.996-.018 22.815-9.592 23.516-17.746l-.088-2.693c-5.963 6.725-15.16 9.554-22.605 9.216v-3.8a2.027 2.027 0 00-2.81-1.851l-17.796 7.639a2.559 2.559 0 00-.235 4.564z"
                                    data-original="#000000"
                                  ></path>
                                  <path
                                    d="M51.896 34.123l1.841-1.944a17.582 17.582 0 00-4.143-6.581.988.988 0 00-1.665.725c.226 4.1-.79 10.006-1.86 12.35q.363-.195.737-.416a23.582 23.582 0 005.09-4.134z"
                                    data-original="#000000"
                                  ></path>
                                </g>
                              </svg>
                            </span>{" "}
                            {props.transferData.exchange_data.receiving_currency}
                          </h5>
                        </div>
                        <div className="single-transaction-info-card">
                          <h4>Fees:</h4>
                          <h5>
                            $
                            {(
                              props.transferData.exchange_data.charged_amount.amount -
                              props.transferData.exchange_data.converted_amount.amount
                            ).toFixed(2)}
                          </h5>
                        </div>
                        <div className="single-transaction-info-card">
                          <h4>Total Payable:</h4>
                          <h5>${props.transferData.exchange_data.charged_amount.amount}</h5>
                        </div>
                        <div className="single-transaction-box-action margin-top-sm">
                          <Button
                            type="button"
                            className="efi-primary-btn w-100 mt-3"
                            disabled={createQuote.buttonDisable}
                            onClick={() => props.setStep(props.step + 1)}
                          >
                            Confirm Amount
                          </Button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="single-transaction-box-action margin-top-sm">
                        <Button
                          type="button"
                          className="efi-primary-btn w-100 mt-3"
                          disabled={createQuote.buttonDisable || !selectedRecivingCurrency}
                          onClick={() => handleSubmit(values)}
                        >
                          Generate Quote
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* <div className="single-transaction-box-action">
                    {exchangeData && !createQuote.buttonDisable ? (
                      <Button
                        type="submit"
                        className="efi-primary-btn w-100 mt-3"
                        onClick={() => props.setStep(props.step + 1)}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="efi-primary-btn w-100"
                        disabled={
                          createQuote.buttonDisable ||
                          (createQuote?.data?.converted_amount?.amount ==
                            values.amount &&
                            createQuote?.data?.recipient_amount?.currency ==
                              values.receiving_currency)
                        }
                      >
                        {createQuote.buttonDisable ? (
                          <ButtonLoader />
                        ) : (
                          "Confirm Amount"
                        )}
                      </Button>
                    )}
                  </div> */}
                </FORM>
              )}
            </Formik>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
};

export default SingleTransactionFields;
